body {
  background-color: #2a2a2a;
  font-family: Open Sans;
}

.dx-widget {
  font-family: Open Sans;
}

.copyright {
  background: #121a21;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.copyright-info>p>a {
  color: #c40101;
  text-decoration: none;
  font-size: 14px;
}

.copyright-info>p>span {
  color: #6b7c93;
  font-size: 14px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.tab-panel {
  margin: auto;
  width: 90%;
}

.tab-panel h1 {
  font-size: 20px;
}

.tab-panel h1,
.tab-panel p {
  margin: 0;
}

/* This is important for making the panel at a fixed distance from the top and bottom */
.tab-panel>.dx-tabpanel {
  width: 90% !important;
  bottom: 90px;
  position: absolute;
  top: 200px;
}

.dx-theme-material .tab-panel h1 {
  align-self: center;
}

.options {
  padding: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 340px;
  top: 0;
  background-color: rgba(191, 191, 191, 0.15);
}

.options>.caption {
  font-weight: 500;
  font-size: 18px;
}

.option {
  margin-top: 10px;
}

.option>.caption {
  margin-top: 10px;
  display: inline-block;
}

.option>.dx-tagbox {
  margin-top: 2px;
}

.title {
  font-size: 1.3em;
  font-weight: 650;
}

.tab-panel p.product-name {
  font-size: 1.2em;
  font-weight: 600;
  padding-left: 1em;
  padding-right: 1em;
}

.tab-panel p.flag {
  margin-bottom: 10px;
}

.flag-img {
  height: 20px;
}

.flag-txt {
  vertical-align: top;
}

.logo {
  height: 10em;
  display: block;
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
}

.flag-sm {
  height: 10px;
  padding-left: 0.5em;
}

.country-purchase {
  padding-left: 0.5em;
}

.bottle {
  height: 340px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.tile-view-item {
  border-top-color: #4d4d4d;
  border-top-width: thin;
  border-top-style: solid;
  margin-top: 30px;
  text-align: center;
}

.product-link {
  padding-top: 0.5em;
}

.product-link>a {
  color: #c40101;
  text-decoration: none;
}

/* This is important for making the panel at a fixed distance from the top and bottom */
.tree-view-template>.dx-scrollable-wrapper>.dx-scrollable-container>.dx-scrollable-content>.dx-tileview-wrapper {
  bottom: 90px;
  position: absolute;
  top: 10px;
}

@media only screen and (max-width: 800px) {

  /* styles for devices with a maximum screen width of 600px */
  .logo {
    height: 5em;
  }

  /* This is important for making the panel at a fixed distance from the top and bottom */
  .tab-panel>.dx-tabpanel {
    bottom: 70px;
    top: 120px;
  }

  .bottle {
    height: 240px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .tile-view-item {
    margin-top: 15px;
  }

  .tab-panel p.product-name {
    font-size: 1em;
  }

  .country-purchase {
    font-size: .7em;
  }

  .flag-sm {
    height: 7px;
  }

  .flag-img {
    height: 10px;
  }

  .flag.text {
    font-size: 0.9em;
  }

  .product-style {
    font-size: 0.9em;
  }

  p.product-volume {
    font-size: 0.9em;
    margin-top: 0.3em;
  }

  .product-link {
    font-size: 0.9em;
  }

  .copyright {
    padding: 0;
  }
}
